<template>
  <div class="box">
    <div class="codeBox" id="imgBox">
      <div class="content">
        <div class="name">商户：{{ data.merName }}</div>
        <div class="code">
          <img class="code-img" :src="code" alt="" width="100%" />
        </div>
        <div class="tipBox">
          <div class="realName">请使用：{{ data.commissary }}</div>
          <div class="tip">支付宝扫描以上二维码确认支付宝支付商户信息</div>
        </div>
      </div>
      <div class="sign">注：为确保支付宝交易正常，支付宝扫码后，核实收款商 户号时，请勾选全部商户号。</div>
    </div>
    <img :src="codeImg" width="100%" />
    <div v-if="codeImg && iswx == false" class="submit-btn" @click="downImg">保存二维码</div>
    <div v-if="codeImg" class="tips">长按二维码保存</div>
  </div>
</template>

<script>
import { codeInfo } from "@/api/bind";
import { Toast } from "vant";
import html2canvas from "html2canvas";
import { qrcanvas } from "qrcanvas";
export default {
  data() {
    return {
      dmCode: this.$route.query.dmCode ? this.$route.query.dmCode : "",
      type: this.$route.query.type ? this.$route.query.type : "",
      codeImg: "",
      code: "",
      data: "",
      iswx: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    this.isWeiXin();
    this.getInfo();
  },
  mounted() {},
  methods: {
    isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //   alert(JSON.stringify(ua)); //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.iswx = true;
      } else {
        this.iswx = false;
      }
      console.log(this.iswx);
    },
    getInfo() {
      let params = {
        dmCode: this.dmCode,
        type: this.type,
      };
      codeInfo(params)
        .then((res) => {
          //   console.log(res);
          this.data = res;
          let link = this.data.authLink;
          let canvas = qrcanvas({
            data: link,
            size: 340,
          });
          // canvas转图片
          this.code = canvas.toDataURL("image/png");
          if (this.code) {
            setTimeout(() => {
              this.submit();
            }, 1000);
          }
        })
        .catch((err) => {
          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },
    submit() {
      html2canvas(document.querySelector("#imgBox"), {
        backgroundColor: null,
        useCORS: true,
      }).then((canvas) => {
        // canvas转图片
        this.codeImg = canvas.toDataURL("image/png");
        // this.downloadImage(this.codeImg);
      });
    },
    downImg() {
      this.downloadImage(this.codeImg, this.data);
    },
    //下载图片
    downloadImage(imgUrl, data) {
      let tmpArr = imgUrl.split("/");
      let fileName = tmpArr[tmpArr.length - 1];
      window.URL = window.URL || window.webkitURL;
      let xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          let blob = this.response;
          let fileUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          (document.body || document.documentElement).appendChild(a);
          a.href = fileUrl;
          if ("download" in a) {
            // console.log(this.data);
            a.download = data.commissary + "支付宝实名认证码";
          } else {
            a.setAttribute("download", fileName);
          }
          a.target = "_self";
          a.click();
          a.remove();
        }
      };
      xhr.send();
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.codeBox {
  width: 100%;
  height: 950px;
  background: url("../../assets/bind/Alipay.png") center center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: fixed;
  left: 999999px;
  top: 999999px;
  z-index: -1;
  .content {
    margin: 163px 45px 20px 45px;
    background: #fff;
    width: 660px;
    height: 684px;
    box-sizing: border-box;
    border-radius: 20px;
    // overflow: hidden;
    .name {
      padding: 40px 92px;
      color: #333;
      font-size: 28px;
      font-weight: 500;
      text-align: center;
    }
    .code {
      width: 340px;
      height: 340px;
      margin: 0px 160px;
    }
    .tipBox {
      width: 580px;
      height: 140px;
      margin: 40px 40px 45px 40px;
      padding: 20px 17px;
      background: #f5f5f5;
      border-radius: 12px;
      box-sizing: border-box;
      .realName {
        color: #333;
        font-size: 28px;
        text-align: center;
      }
      .tip {
        color: #666666;
        text-align: center;
        font-size: 24px;
        padding-top: 15px;
        line-height: 33px;
      }
    }
  }
  .sign {
    width: 648px;
    height: 66px;
    margin: 0px 54px 17px 48px;
    font-size: 24px;
    color: #666666;
  }
}
.tips {
  width: 100%;
  text-align: center;
  color: #999;
  position: absolute;
  z-index: 999;
  top: 805px;
  font-size: 26px;
}
.submit-btn {
  width: 620px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: #fff;
  border-radius: 45px;
  color: #ef3535;
  font-size: 32px;
  font-weight: 400;
  margin: 209px 65px 0 65px;
}
</style>
